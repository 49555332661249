/**
 * Highlighting style sheet
 */

.hljs-keyword {
  color: var(--code-pink);
  font-style: italic;
  font-weight: bold;
}

.hljs-string {
  color: var(--code-green);
}

.hljs-number {
  color: var(--code-purple);
}

.hljs-attr {
  color: var(--code-pink);
}

.hljs-params {
  color: var(--code-blue);
}