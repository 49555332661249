body {
  --blue: rgb(8, 178, 227);
  --red: rgb(238, 99, 82);

  --code-pink: #ff7675;
  --code-green: #55efc4;
  --code-purple: #a29bfe;
  --code-blue: #74b9ff;
  --code-yellow: #fdcb6e;

  --title-font: "Montserrat", sans-serif;
}
