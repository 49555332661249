@import url("https://fonts.googleapis.com/css?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");

@import "./highlight.css";
@import "./variables.css";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --red: #ee6352;
  --blue: #08b2e3;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
}

p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  color: #333;
}

pre code {
  background: #222;
  display: block;
  color: white;
  padding: 1rem;
  width: 100%;
  border-radius: 3px;
  overflow-x: visible;
  word-wrap: wrap;
  font-family: "Dank Mono", monospace;
}

audio {
  margin: 32px 0;
  width: 100%;
}

/* Mobile */

@media screen and (max-width: 768px) {
  body {
    --title-size: 2.5rem;
  }
}
